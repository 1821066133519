import CollaboratorSettings from './CollaboratorSettings';
import ModalButton from './ModalButton';
import PrintDocument from './PrintDocument';
import PropTypes from 'prop-types';
import React, {useCallback, useContext} from 'react';
import UserMenu, {UserButton} from './UserMenu';
import {
  Box,
  Button,
  Flex,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Tooltip,
  chakra
} from '@chakra-ui/react';
import {
  FiChevronRight,
  FiFileText,
  FiPlus,
  FiSearch,
  FiSettings,
  FiUsers,
  FiX
} from 'react-icons/fi';
import {ProjectContext} from '../utils';
import {pdf} from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import {useWindowScroll} from 'react-use';

function LabeledButton({label, ...props}) {
  return (
    <Tooltip label={label}>
      <div>
        <UserButton {...props} />
      </div>
    </Tooltip>
  );
}

LabeledButton.propTypes = {
  label: PropTypes.string.isRequired
};

const HEADER_BUTTON_SPACE = {base: 1, md: 2};

function HeaderButtons(props) {
  return (
    <HStack
      ml="auto"
      mr={HEADER_BUTTON_SPACE}
      spacing={HEADER_BUTTON_SPACE}
      {...props}
    />
  );
}

export default function ProjectHeader({
  transformImage,
  query,
  setQuery,
  spacing,
  openSettings
}) {
  const {
    selected,
    viewFullSong,
    blocks,
    canEdit,
    project,
    createBlock
  } = useContext(ProjectContext);
  const {y} = useWindowScroll();

  const downloadPdf = useCallback(async () => {
    const blob = await pdf(
      <PrintDocument
        name={project.name}
        blocks={blocks}
        notes={project.notes}
        transformImage={transformImage}
      />
    ).toBlob();
    saveAs(blob, project.name + '.pdf');
    trackCustomEvent({
      category: 'PDF',
      action: 'Download'
    });
  }, [project.name, project.notes, blocks, transformImage]);

  return (
    <Flex
      align="center"
      mb={spacing}
      top={spacing}
      h="12"
      bg="white"
      borderWidth="2px"
      borderColor="current"
      rounded="lg"
      position="sticky"
      zIndex="sticky"
      shadow={y > 80 ? 'lg' : 'none'}
    >
      {query !== null ? (
        <>
          <chakra.input
            autoFocus
            flexGrow="1"
            lineHeight="44px"
            px={{base: 4, md: 5}}
            outline="none"
            bg="transparent"
            color="gray.800"
            fontFamily="heading"
            placeholder="Search blocks..."
            value={query}
            onChange={event => setQuery(event.target.value)}
            onKeyDown={event => {
              if (event.key === 'Escape') {
                setQuery(null);
              }
            }}
          />
          <UserButton
            icon={<FiX />}
            mx={HEADER_BUTTON_SPACE}
            onClick={() => setQuery(null)}
          />
        </>
      ) : (
        <>
          <Flex w="0" grow="1" align="center" mx={HEADER_BUTTON_SPACE}>
            <Box display={{md: 'none'}}>
              <UserMenu />
            </Box>
            <Button
              lineHeight="none"
              variant="ghost"
              rounded="full"
              fontSize="md"
              size="sm"
              onClick={viewFullSong}
              as="a"
              href="#"
            >
              <Box isTruncated>{project.name}</Box>
            </Button>
            {selected && (
              <>
                <Box
                  as={FiChevronRight}
                  flexShrink="0"
                  fontSize="xl"
                  mr="1"
                  ml="-1"
                />
                <chakra.span
                  isTruncated
                  aria-current="page"
                  fontFamily="heading"
                  fontWeight="medium"
                >
                  {selected.name || 'New block'}
                </chakra.span>
              </>
            )}
          </Flex>
          <HeaderButtons>
            {project.isOwn && (
              <>
                <LabeledButton
                  label="Project settings"
                  icon={<FiSettings />}
                  onClick={openSettings}
                />
                <Box>
                  <ModalButton
                    size="lg"
                    renderButton={openModal => (
                      <LabeledButton
                        onClick={openModal}
                        label="Collaborators"
                        icon={<FiUsers />}
                      />
                    )}
                  >
                    {closeModal => (
                      <ModalContent>
                        <ModalHeader>
                          {project.isTrial
                            ? 'Upgrade required'
                            : 'Share with your team'}
                        </ModalHeader>
                        <ModalCloseButton />
                        {project.isTrial ? (
                          <ModalBody>
                            <Text>
                              <strong>Collaborators</strong> are only available
                              for Pro projects. Open your project settings
                              dialog{' '}
                              <Box
                                as={FiSettings}
                                h="1.25em"
                                w="1.25em"
                                display="inline"
                                verticalAlign="text-bottom"
                              />{' '}
                              to upgrade to Pro.
                            </Text>
                          </ModalBody>
                        ) : (
                          <CollaboratorSettings
                            projectId={project.id}
                            collaborators={project.collaborators}
                          />
                        )}
                        <ModalFooter>
                          <Button onClick={closeModal}>Done</Button>
                        </ModalFooter>
                      </ModalContent>
                    )}
                  </ModalButton>
                </Box>
              </>
            )}
            <LabeledButton
              isDisabled={!project.blocks.length}
              label="Search blocks"
              icon={<FiSearch />}
              onClick={() => setQuery('')}
            />
            <Box display={{base: 'none', md: 'block'}}>
              <LabeledButton
                isDisabled={!project.blocks.length}
                label="Download PDF"
                icon={<FiFileText />}
                onClick={downloadPdf}
              />
            </Box>
            {canEdit && (
              <LabeledButton
                label="Create a block"
                icon={<FiPlus />}
                onClick={createBlock}
              />
            )}
          </HeaderButtons>
        </>
      )}
    </Flex>
  );
}

ProjectHeader.propTypes = {
  transformImage: PropTypes.func.isRequired,
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
  spacing: PropTypes.array.isRequired,
  openSettings: PropTypes.func.isRequired
};
