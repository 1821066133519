import CollaboratorMenu from './CollaboratorMenu';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  ModalBody,
  Stack,
  Text
} from '@chakra-ui/react';
import {COLLABORATOR_FRAGMENT, GET_PROJECT} from '../utils/queries';
import {FiArrowRight, FiMail} from 'react-icons/fi';
import {UserContext} from '../utils';
import {gql, useMutation} from '@apollo/client';

const CREATE_COLLABORATOR = gql`
  mutation CreateCollaborator($projectId: ID!, $email: String!) {
    createCollaborator(projectId: $projectId, email: $email) {
      ...CollaboratorFragment
    }
  }
  ${COLLABORATOR_FRAGMENT}
`;

export default function CollaboratorSettings({projectId, collaborators}) {
  const {user} = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [createCollaborator, {loading, error}] = useMutation(
    CREATE_COLLABORATOR,
    {
      onCompleted: () => setEmail(''),
      update(cache, {data}) {
        const queryOptions = {
          query: GET_PROJECT,
          variables: {
            projectId
          }
        };

        const {project} = cache.readQuery(queryOptions);
        cache.writeQuery({
          ...queryOptions,
          data: {
            project: {
              ...project,
              collaborators: [data.createCollaborator, ...project.collaborators]
            }
          }
        });
      }
    }
  );

  function handleSubmit(event) {
    event.preventDefault();
    createCollaborator({
      variables: {
        email,
        projectId
      }
    });
  }

  return (
    <ModalBody as={Stack} spacing="4">
      {error && <Text color="red.500">{error.message}</Text>}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Flex>
          <Input
            isRequired
            size="lg"
            placeholder="Enter collaborator email"
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            roundedEnd="none"
            borderRightWidth="0"
          />
          <Button
            type="submit"
            isLoading={loading}
            colorScheme="pink"
            roundedStart="none"
            size="lg"
            rightIcon={<FiArrowRight />}
          >
            Add
          </Button>
        </Flex>
      </form>
      <Stack spacing="3">
        {[user, ...collaborators].map(collaborator => {
          const isSelf = collaborator === user;
          return (
            <Flex align="center" key={collaborator.id}>
              <Avatar
                mr="3"
                size="sm"
                src={collaborator.avatar}
                name={collaborator.name}
              />
              <Box mr="auto" lineHeight="short">
                {isSelf || collaborator.active ? (
                  <>
                    <div>
                      {collaborator.name}
                      {isSelf && ' (you)'}
                    </div>
                    <Box fontSize="sm">{collaborator.email}</Box>
                  </>
                ) : (
                  <>
                    <Flex align="center">
                      <Box mr="1" as={FiMail} />
                      {collaborator.email}
                    </Flex>
                    <Box fontSize="sm">Invite sent</Box>
                  </>
                )}
              </Box>
              {!isSelf && (
                <CollaboratorMenu
                  collaborator={collaborator}
                  projectId={projectId}
                />
              )}
            </Flex>
          );
        })}
      </Stack>
    </ModalBody>
  );
}

CollaboratorSettings.propTypes = {
  projectId: PropTypes.string.isRequired,
  collaborators: PropTypes.array.isRequired
};
