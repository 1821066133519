import GetProject from '../../components/GetProject';
import Layout from '../../components/Layout';
import NoSsr from '@mpth/react-no-ssr';
import PropTypes from 'prop-types';
import React from 'react';
import RequireAuth from '../../components/RequireAuth';

export default function Project({params}) {
  return (
    <Layout>
      <NoSsr>
        <RequireAuth>
          <GetProject variables={{projectId: params.id}} />
        </RequireAuth>
      </NoSsr>
    </Layout>
  );
}

Project.propTypes = {
  params: PropTypes.object.isRequired
};
