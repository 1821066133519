import PropTypes from 'prop-types';
import React, {Suspense, useContext, useRef} from 'react';
import SubmitButton from './SubmitButton';
import {Button, ButtonGroup, Flex, Heading} from '@chakra-ui/react';
import {ProjectContext} from '../utils';
import {gql, useMutation} from '@apollo/client';

const NotesInput = React.lazy(() => import('./NotesInput'));

const UPDATE_PROJECT_NOTES = gql`
  mutation UpdateProjectNotes($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      notes
    }
  }
`;

export default function GeneralNotesForm({closeForm}) {
  const editorRef = useRef();
  const {project} = useContext(ProjectContext);
  const [updateProjectNotes, {loading}] = useMutation(UPDATE_PROJECT_NOTES, {
    onCompleted: closeForm
  });

  function handleSubmit(event) {
    event.preventDefault();
    updateProjectNotes({
      variables: {
        id: project.id,
        input: {notes: editorRef.current.getValue()}
      }
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex align="center" mb="3">
        <Heading size="lg">Notes</Heading>
        <ButtonGroup size="sm" ml="auto" colorScheme="indigo">
          <Button onClick={closeForm} variant="ghost">
            Cancel
          </Button>
          <SubmitButton isLoading={loading} />
        </ButtonGroup>
      </Flex>
      <Suspense fallback={null}>
        <NotesInput initialValue={project.notes || ''} ref={editorRef} />
      </Suspense>
    </form>
  );
}

GeneralNotesForm.propTypes = {
  closeForm: PropTypes.func.isRequired
};
