import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import React from 'react';
import poppins from '@fontsource/poppins/files/poppins-latin-500-normal.woff';
import poppinsBold from '@fontsource/poppins/files/poppins-latin-700-normal.woff';
import roboto from '@fontsource/roboto/files/roboto-latin-400-normal.woff';
import robotoBold from '@fontsource/roboto/files/roboto-latin-700-normal.woff';
import unwrapImages from 'remark-unwrap-images';
import {Document, Font, Image, Page, Text, View} from '@react-pdf/renderer';
import {getTimecode} from '../utils';

Font.register({
  family: 'Poppins',
  fonts: [{src: poppins}, {src: poppinsBold, fontWeight: 700}]
});

Font.register({
  family: 'Roboto',
  fonts: [{src: roboto}, {src: robotoBold, fontWeight: 700}]
});

function RemoteImage({src, ...props}) {
  return (
    <Image
      cache={false}
      src={{
        uri: src + '?cache=' + Math.random().toString()
      }}
      {...props}
    />
  );
}

RemoteImage.propTypes = {
  src: PropTypes.string.isRequired
};

const headingFontSizes = {
  1: 27,
  2: 22.5,
  3: 18,
  4: 15,
  5: 13.5,
  6: 12
};

function Heading({level, ...props}) {
  return (
    <View
      style={{
        marginBottom: 12,
        lineHeight: 1.25,
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: headingFontSizes[level]
      }}
      {...props}
    />
  );
}

Heading.propTypes = {
  level: PropTypes.number.isRequired
};

function ListItem({index, ordered, children, ...props}) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        fontSize: 12,
        lineHeight: 1.5,
        marginTop: index && 9
      }}
      {...props}
    >
      <Text style={{marginRight: 9}}>{ordered ? index + 1 + '.' : '•'}</Text>
      {children}
    </View>
  );
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  ordered: PropTypes.bool.isRequired
};

const renderers = {
  paragraph(props) {
    return (
      <Text
        style={{
          marginBottom: 12,
          fontSize: 12,
          lineHeight: 1.5
        }}
        {...props}
      />
    );
  },
  list(props) {
    return <View style={{marginBottom: 12}} {...props} />;
  },
  listItem: ListItem,
  heading: Heading,
  image: RemoteImage,
  text: Text,
  strong(props) {
    return <Text style={{fontFamily: 'Roboto', fontWeight: 700}} {...props} />;
  }
};

function Block({block, transformImage}) {
  return (
    <View wrap={false} style={{marginBottom: 24}}>
      <Text
        style={{
          fontFamily: 'Poppins',
          fontWeight: 700,
          fontSize: 22.5,
          lineHeight: 1.2,
          letterSpacing: -0.75
        }}
      >
        {block.timecode || getTimecode(block)}
      </Text>
      <Text
        style={{
          fontSize: 15,
          lineHeight: 1.375
        }}
      >
        {block.name}
      </Text>
      {block.description?.length > 0 && (
        <View style={{marginTop: 12}}>
          <Markdown
            source={block.description}
            renderers={renderers}
            unwrapDisallowed
            plugins={[unwrapImages]}
            transformImageUri={transformImage}
            allowedTypes={[
              'paragraph',
              'list',
              'listItem',
              'heading',
              'image',
              'text',
              'strong'
            ]}
          />
        </View>
      )}
    </View>
  );
}

Block.propTypes = {
  block: PropTypes.object.isRequired,
  transformImage: PropTypes.func.isRequired
};

export default function PrintDocument({name, transformImage, blocks, notes}) {
  return (
    <Document>
      <Page
        style={{
          padding: '1in',
          fontFamily: 'Roboto'
        }}
      >
        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 18,
            fontSize: 12,
            fontFamily: 'Poppins'
          }}
        >
          <Text>{name}</Text>
          <Text
            fixed
            style={{minHeight: 14}}
            render={({pageNumber, totalPages}) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
        {notes ? (
          <Block
            block={{timecode: 'Notes', description: notes}}
            transformImage={transformImage}
          />
        ) : null}
        {blocks.map(block => (
          <Block key={block.id} block={block} transformImage={transformImage} />
        ))}
      </Page>
    </Document>
  );
}

PrintDocument.propTypes = {
  name: PropTypes.string.isRequired,
  transformImage: PropTypes.func.isRequired,
  blocks: PropTypes.array.isRequired,
  notes: PropTypes.string
};
