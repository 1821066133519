import Block, {BLOCK_SPACING, BlockWrapper} from './Block';
import GeneralNotes from './GeneralNotes';
import PlayBar from './PlayBar';
import ProjectHeader from './ProjectHeader';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import UpdateProjectForm from './UpdateProjectForm';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {Link as GatsbyLink, graphql, useStaticQuery} from 'gatsby';
import {Global} from '@emotion/react';
import {NEW_BLOCK_ID, ProjectContext, QueryContext} from '../utils';

export default function ProjectMain() {
  const {blocks, project, selected, canEdit, createBlock} = useContext(
    ProjectContext
  );

  const {config} = useStaticQuery(
    graphql`
      {
        config {
          filesBaseUrl
        }
      }
    `
  );

  const transformImage = useCallback(
    uri => {
      try {
        const {href} = new URL(uri);
        return href;
      } catch (error) {
        return config.filesBaseUrl + uri;
      }
    },
    [config.filesBaseUrl]
  );

  const [query, setQuery] = useState(null);
  const filteredBlocks = useMemo(
    () =>
      blocks
        // filter blocks if there's an active search query
        .filter(
          block =>
            !query ||
            block.name.toLowerCase().includes(query) ||
            block.description.toLowerCase().includes(query)
        )
        .concat(selected && selected.id === NEW_BLOCK_ID ? [selected] : []),
    [query, blocks, selected]
  );

  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <Flex
      as="main"
      direction="column"
      grow="1"
      minH="full"
      pos="relative"
      zIndex="base"
    >
      <Global
        styles={{
          html: {
            scrollPaddingTop: 80
          },
          body: {
            overscrollBehaviorX: 'none'
          }
        }}
      />
      <Flex direction="column" grow="1" p={BLOCK_SPACING}>
        <ProjectHeader
          transformImage={transformImage}
          query={query}
          setQuery={setQuery}
          spacing={BLOCK_SPACING}
          openSettings={onOpen}
        />
        {!filteredBlocks.length && (query || (!canEdit && !project.notes)) ? (
          <Box m="auto" textAlign="center" fontSize="lg">
            <Heading fontWeight="semibold" mb="2">
              {query ? '(╯°□°）╯︵ ┻━┻' : '(≧︿≦)'}
            </Heading>
            {query ? (
              <Text>No matches found for this query</Text>
            ) : (
              <Text>This project has no blocks</Text>
            )}
          </Box>
        ) : (
          <QueryContext.Provider value={{query, transformImage}}>
            <Stack spacing={{base: 3, md: 4}}>
              {(canEdit || project.notes) && (
                <GeneralNotes transformImage={transformImage} />
              )}
              {filteredBlocks.length ? (
                filteredBlocks.map(block => (
                  <Block
                    key={block.id}
                    block={block}
                    transformImage={transformImage}
                  />
                ))
              ) : (
                <BlockWrapper
                  isStatic
                  rounded="xl"
                  borderWidth="2px"
                  borderStyle="dashed"
                  p={BLOCK_SPACING}
                >
                  <Heading as="h3" size="lg" mb="2">
                    Start creating
                  </Heading>
                  <Text maxW="container.sm" mb="2">
                    Welcome to your new Playback project! We use{' '}
                    <strong>blocks</strong> to separate your song into
                    independently-playable sections. Use the button in the
                    sidebar to the left or the one in the header above to create
                    a new block at any time.
                  </Text>
                  <Text mb="4">
                    If you&apos;re new to Playback, check out our short tutorial
                    to learn how to use the tool. 💫
                  </Text>
                  <ButtonGroup>
                    <Button
                      rounded="full"
                      colorScheme="indigo"
                      onClick={createBlock}
                    >
                      Create block
                    </Button>
                    <Button
                      rounded="full"
                      variant="ghost"
                      as={GatsbyLink}
                      to="/guide"
                    >
                      Read the tutorial
                    </Button>
                  </ButtonGroup>
                </BlockWrapper>
              )}
            </Stack>
          </QueryContext.Provider>
        )}
      </Flex>
      <PlayBar openSettings={onOpen} />
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit project settings</ModalHeader>
          <ModalCloseButton />
          <UpdateProjectForm onCancel={onClose} />
        </ModalContent>
      </Modal>
    </Flex>
  );
}
