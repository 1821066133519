import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react';
import {COLLABORATOR_FRAGMENT, GET_PROJECT} from '../utils/queries';
import {FiChevronDown} from 'react-icons/fi';
import {gql, useMutation} from '@apollo/client';

const DELETE_COLLABORATOR = gql`
  mutation DeleteCollaborator($projectId: ID!, $userId: ID!) {
    deleteCollaborator(projectId: $projectId, userId: $userId) {
      id
    }
  }
`;

const UPDATE_COLLABORATOR = gql`
  mutation UpdateCollaborator($projectId: ID!, $userId: ID!, $role: Role!) {
    updateCollaborator(projectId: $projectId, userId: $userId, role: $role) {
      ...CollaboratorFragment
    }
  }
  ${COLLABORATOR_FRAGMENT}
`;

export default function CollaboratorMenu({projectId, collaborator}) {
  const variables = useMemo(
    () => ({
      projectId,
      userId: collaborator.id
    }),
    [projectId, collaborator.id]
  );

  const [deleteCollaborator, {loading: deleting}] = useMutation(
    DELETE_COLLABORATOR,
    {
      variables,
      update(cache, {data}) {
        const queryOptions = {
          query: GET_PROJECT,
          variables: {
            projectId
          }
        };

        const {project} = cache.readQuery(queryOptions);
        cache.writeQuery({
          ...queryOptions,
          data: {
            project: {
              ...project,
              collaborators: project.collaborators.filter(
                collaborator => collaborator.id !== data.deleteCollaborator.id
              )
            }
          }
        });
      }
    }
  );

  const [
    updateCollaborator,
    {loading: updating}
  ] = useMutation(UPDATE_COLLABORATOR, {variables});

  return (
    <Menu>
      <MenuButton
        as={Button}
        isLoading={updating || deleting}
        size="sm"
        rightIcon={<FiChevronDown />}
      >
        {collaborator.role === 'edit' ? 'Editor' : 'Viewer'}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={collaborator.role}
          onChange={role => updateCollaborator({variables: {role}})}
          title="Permission level"
          type="radio"
        >
          <MenuItemOption value="edit">Can edit blocks</MenuItemOption>
          <MenuItemOption value="view">Can view (read only)</MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem onClick={deleteCollaborator}>Remove collaborator</MenuItem>
      </MenuList>
    </Menu>
  );
}

CollaboratorMenu.propTypes = {
  collaborator: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};
