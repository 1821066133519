import ProjectForm from './ProjectForm';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';
import {BILLING_WRAPPER_PROPS, ProjectContext, UserContext} from '../utils';
import {Box, Checkbox, ModalBody, useToast} from '@chakra-ui/react';
import {PROJECT_FRAGMENT} from '../utils/queries';
import {PaymentMethodControl} from './PaymentMethod';
import {gql} from '@apollo/client';
import {graphql, useStaticQuery} from 'gatsby';

const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: ID!
    $isTrial: Boolean
    $file: FileInput
    $input: ProjectInput!
  ) {
    updateProject(id: $id, isTrial: $isTrial, file: $file, input: $input) {
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export default function UpdateProjectForm({onCancel}) {
  const toast = useToast();
  const {user} = useContext(UserContext);
  const {project} = useContext(ProjectContext);
  const [trial, setTrial] = useState(project.isTrial);

  const {config} = useStaticQuery(
    graphql`
      {
        config {
          paidProjectCost
        }
      }
    `
  );

  const isUpgrading = useMemo(() => project.isTrial && !trial, [
    project,
    trial
  ]);

  return (
    <>
      {project.isTrial && (
        <ModalBody>
          <Box p="3" {...BILLING_WRAPPER_PROPS}>
            <Checkbox
              isChecked={trial}
              onChange={event => setTrial(event.target.checked)}
              fontWeight="medium"
            >
              Upgrade to Pro (${config.paidProjectCost})
            </Checkbox>
            {!trial && (
              <PaymentMethodControl mt="3" paymentMethod={user.paymentMethod} />
            )}
          </Box>
        </ModalBody>
      )}
      <ProjectForm
        headerText="Project settings"
        mutation={UPDATE_PROJECT}
        buttonText={
          isUpgrading
            ? `Save and upgrade ($${config.paidProjectCost})`
            : 'Save changes'
        }
        isTrial={trial}
        project={project}
        onCancel={onCancel}
        mutationOptions={{
          variables: {
            id: project.id
          },
          onCompleted() {
            onCancel();
            toast({
              title: 'Success',
              description: 'Your project has been updated',
              status: 'success'
            });
          }
        }}
      />
    </>
  );
}

UpdateProjectForm.propTypes = {
  onCancel: PropTypes.func.isRequired
};
