import PropTypes from 'prop-types';
import RateButtons, {PlayBarButton} from './RateButtons';
import React, {useContext} from 'react';
import Track, {TrackBase} from './Track';
import {
  Box,
  ButtonGroup,
  Flex,
  Tooltip,
  useBreakpointValue
} from '@chakra-ui/react';
import {FiUploadCloud} from 'react-icons/fi';
import {MdPause, MdPlayArrow, MdSkipNext, MdSkipPrevious} from 'react-icons/md';
import {ProjectContext, isTyping} from '../../utils';
import {useKey} from 'react-use';

function PlayButton() {
  const {playing, togglePlaying} = useContext(ProjectContext);

  // play/pause the audio when the space bar is pressed
  useKey(
    event => event.code === 'Space' && !isTyping(event),
    event => {
      event.preventDefault();
      togglePlaying();
    }
  );

  return (
    <PlayBarButton
      icon={playing ? <MdPause /> : <MdPlayArrow />}
      onClick={togglePlaying}
    />
  );
}

export default function PlayBar({openSettings}) {
  const {sound, duration, selected, setSelected, blocks, project} = useContext(
    ProjectContext
  );
  const buttonSize = useBreakpointValue({base: 'lg', md: 'md'});

  function prevBlock() {
    let seekTo = 0;
    if (selected) {
      const index = blocks.findIndex(block => block.id === selected.id);
      // if the current block is the first, or at least one second has passed,
      // restart the block
      if (!index || sound.current?.seek() >= selected.start + 1) {
        seekTo = selected.start;
      } else {
        // the condition must be structured in this way to account for file-less
        // projects where sound.current is undefined
        const nextSelected = blocks[index - 1];
        setSelected(nextSelected);
        seekTo = nextSelected.start;
      }
    }

    sound.current?.seek(seekTo);
  }

  function nextBlock() {
    if (blocks.length) {
      let nextSelected = blocks[0];
      if (selected) {
        const index = blocks.findIndex(block => block.id === selected.id);
        if (index === blocks.length - 1) {
          // don't do anything when we reach the last block
          return;
        }

        nextSelected = blocks[index + 1];
      }

      setSelected(nextSelected);
      sound.current?.seek(nextSelected.start);
    }
  }

  return (
    <Flex
      direction={{base: 'column-reverse', md: 'row'}}
      align={{md: 'center'}}
      mt="auto"
      bg="black"
      color="white"
      position="sticky"
      bottom="0"
      py={{base: 3, md: 2}}
      px={{base: 6, md: 3}}
    >
      <ButtonGroup mx="auto" size={buttonSize}>
        <PlayBarButton onClick={prevBlock} icon={<MdSkipPrevious />} />
        {project.file ? (
          <PlayButton />
        ) : (
          <Tooltip label="Upload a song">
            <PlayBarButton onClick={openSettings} icon={<FiUploadCloud />} />
          </Tooltip>
        )}
        <PlayBarButton onClick={nextBlock} icon={<MdSkipNext />} />
      </ButtonGroup>
      {duration ? (
        <Track />
      ) : (
        <TrackBase start="-:--" end="-:--">
          {trackProps => <Box flexGrow="1" opacity="0.4" {...trackProps} />}
        </TrackBase>
      )}
      {project.file && <RateButtons />}
    </Flex>
  );
}

PlayBar.propTypes = {
  openSettings: PropTypes.func.isRequired
};
