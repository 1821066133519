import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import clockTime from 'clock-time';
import {Box, useTheme} from '@chakra-ui/react';
import {ProjectContext} from '../../utils';
import {triangle} from 'polished';
import {useDrag} from 'react-use-gesture';

export default function Marker({trackRef, property, time, min = 0, max = 1}) {
  const {colors, space} = useTheme();
  const [percent, setPercent] = useState(null);
  const {setSelected, duration} = useContext(ProjectContext);

  const bind = useDrag(
    state => {
      const {x, width} = trackRef.current.getBoundingClientRect();
      const value = (state.values[0] - x) / width;
      const clamped = Math.min(max, Math.max(min, value));

      if (state.last) {
        setSelected(prevSelected => ({
          ...prevSelected,
          [property]: clamped * duration
        }));
        setPercent(null);
        return;
      }

      setPercent(clamped);
    },
    {axis: 'x'}
  );

  const hasPercent = percent !== null;
  return (
    <Box
      position="absolute"
      bottom="100%"
      left={`calc(100% * ${hasPercent ? percent : time / duration})`}
      transform="translateX(-50%)"
    >
      <Box
        fontSize="md"
        px="2"
        bg="gray.700"
        cursor="pointer"
        userSelect="none"
        {...bind()}
      >
        {clockTime((hasPercent ? duration * percent : time) * 1000)}
      </Box>
      <Box
        mx="auto"
        style={triangle({
          pointingDirection: 'bottom',
          width: space[3],
          height: space[3],
          foregroundColor: colors.gray[700]
        })}
      />
    </Box>
  );
}

Marker.propTypes = {
  trackRef: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};
