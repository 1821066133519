import NotFound from './NotFound';
import ProjectView from './ProjectView';
import PropTypes from 'prop-types';
import React from 'react';
import {Box, Spinner, chakra} from '@chakra-ui/react';
import {GET_PROJECT} from '../utils/queries';
import {useQuery} from '@apollo/client';

export default function GetProject({variables}) {
  const {data, loading, error} = useQuery(GET_PROJECT, {variables});

  if (loading) {
    return (
      <Box m="auto">
        <Spinner display="block" size="lg" />
      </Box>
    );
  }

  if (error || !data.project) {
    return (
      <Box flexGrow="1">
        <NotFound
          title={error ? 'Ow, an error 😖' : 'Project not found 🤷'}
          description={
            error ? (
              <chakra.span color="red.500">{error.message}</chakra.span>
            ) : (
              "Maybe you don't have access to this project. Check out your projects list for projects that you can access."
            )
          }
          buttonProps={{
            to: '/projects',
            children: 'Back to projects'
          }}
        />
      </Box>
    );
  }

  return <ProjectView project={data.project} />;
}

GetProject.propTypes = {
  variables: PropTypes.object.isRequired
};
