import React, {useContext} from 'react';
import Sidebar, {SidebarButton, SidebarDivider, SidebarFooter} from './Sidebar';
import pluralize from 'pluralize';
import {Box, Button, Flex, IconButton, Stack, Text} from '@chakra-ui/react';
import {FiArrowLeft, FiMusic, FiPlus} from 'react-icons/fi';
import {Link as GatsbyLink} from 'gatsby';
import {MdPause, MdPlayArrow} from 'react-icons/md';
import {ProjectContext, getTimecode} from '../utils';

export default function ProjectSidebar() {
  const {
    playing,
    selected,
    selectBlock,
    setEditing,
    viewFullSong,
    blocks,
    createBlock,
    playPauseBlock,
    canEdit,
    project
  } = useContext(ProjectContext);
  return (
    <Sidebar display={{base: 'none', md: 'flex'}}>
      <Box p="2">
        <SidebarButton
          isFullWidth
          as={GatsbyLink}
          to="/projects"
          leftIcon={<FiArrowLeft />}
        >
          All projects
        </SidebarButton>
        <SidebarDivider as={FiMusic} />
        {blocks.length ? (
          <Stack>
            <Flex align="center" pl="2" textStyle="smallCaps">
              {pluralize('block', blocks.length, true)}
              <SidebarButton
                ml="auto"
                fontSize="sm"
                size="xs"
                px="2"
                rounded="md"
                textStyle="smallCaps"
                as="a"
                href="#"
                isSelected={!selected}
                onClick={viewFullSong}
              >
                Full song
              </SidebarButton>
            </Flex>
            {blocks.map(block => {
              const isSelected = selected?.id === block.id;
              return (
                <Box key={block.id} pos="relative" role="group">
                  <SidebarButton
                    isFullWidth
                    h="auto"
                    p="2"
                    size="lg"
                    textAlign="left"
                    as="a"
                    href={'#' + block.id}
                    isSelected={isSelected}
                    onClick={() => selectBlock(block)}
                    onDoubleClick={() => setEditing(true)}
                  >
                    <Box minWidth="0">
                      <div>{getTimecode(block)}</div>
                      <Box
                        mt="1"
                        fontFamily="body"
                        fontSize="sm"
                        fontWeight="normal"
                        isTruncated
                      >
                        {block.name}
                      </Box>
                    </Box>
                  </SidebarButton>
                  {project.file && (
                    <IconButton
                      size="sm"
                      variant="ghost"
                      icon={
                        isSelected && playing ? <MdPause /> : <MdPlayArrow />
                      }
                      onClick={() => playPauseBlock(block)}
                      pos="absolute"
                      right="2"
                      top="50%"
                      transform="translateY(-50%)"
                      fontSize="xl"
                      colorScheme={isSelected ? 'indigo' : 'gray'}
                      css={
                        !isSelected && {
                          visibility: 'hidden',
                          opacity: 0
                        }
                      }
                      _groupHover={{
                        visibility: 'visible',
                        opacity: 1
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Stack>
        ) : (
          <Box px="2">
            <Box mb="2" textStyle="smallCaps">
              No blocks... yet
            </Box>
            <Text>Your blocks will appear here after you create them.</Text>
          </Box>
        )}
      </Box>
      {canEdit && (
        <SidebarFooter>
          <Button
            isFullWidth
            size="lg"
            rounded="full"
            colorScheme="pink"
            onClick={createBlock}
            leftIcon={<FiPlus />}
          >
            New block
          </Button>
        </SidebarFooter>
      )}
    </Sidebar>
  );
}
