import React, {useContext} from 'react';
import {Box, HStack, IconButton, Tooltip} from '@chakra-ui/react';
import {MdAdd, MdRemove} from 'react-icons/md';
import {ProjectContext} from '../../utils';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import {useUpdateEffect} from 'react-use';

export const PlayBarButton = React.forwardRef((props, ref) => (
  <IconButton
    rounded="full"
    fontSize={{base: '3xl', md: '2xl'}}
    variant="ghost"
    _hover={{bg: 'gray.900'}}
    _active={{bg: 'gray.800'}}
    ref={ref}
    {...props}
  />
));

PlayBarButton.displayName = 'PlayBarButton';

export default function RateButtons() {
  const {sound, rate} = useContext(ProjectContext);

  useUpdateEffect(() => {
    sound.current.howler.rate(rate);
  }, [rate]);

  return (
    <HStack align="center" display={{base: 'none', md: 'flex'}}>
      <PlayBarButton
        icon={<MdRemove />}
        onClick={() => {
          const value = Math.max(rate - 0.5, 0.5);
          sound.current.howler.rate(value);
          trackCustomEvent({
            category: 'Rate',
            action: 'Decrease',
            value
          });
        }}
      />
      <Box w="4ch" fontWeight="medium" textAlign="center">
        <Tooltip label="Playback rate">
          <span>{rate}x</span>
        </Tooltip>
      </Box>
      <PlayBarButton
        icon={<MdAdd />}
        onClick={() => {
          const value = Math.min(rate + 0.5, 2);
          sound.current.howler.rate(value);
          trackCustomEvent({
            category: 'Rate',
            action: 'Increase',
            value
          });
        }}
      />
    </HStack>
  );
}
