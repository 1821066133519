import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text
} from '@chakra-ui/react';
import {GET_PROJECT} from '../utils/queries';
import {gql, useMutation} from '@apollo/client';

const DELETE_BLOCK = gql`
  mutation DeleteBlock($id: ID!) {
    deleteBlock(id: $id) {
      id
      project {
        id
      }
    }
  }
`;

export default function DeleteBlock({variables, children, onCancel}) {
  const [deleteBlock, {loading, error}] = useMutation(DELETE_BLOCK, {
    variables,
    update(cache, {data}) {
      const queryOptions = {
        query: GET_PROJECT,
        variables: {
          projectId: data.deleteBlock.project.id
        }
      };

      const {project} = cache.readQuery(queryOptions);
      cache.writeQuery({
        ...queryOptions,
        data: {
          project: {
            ...project,
            blocks: project.blocks.filter(
              block => block.id !== data.deleteBlock.id
            )
          }
        }
      });
    }
  });

  return (
    <ModalContent>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody as={Stack} spacing="4">
        {error && <Text color="red.500">{error.message}</Text>}
        {children}
      </ModalBody>
      <ModalFooter>
        <Button mr="3" onClick={onCancel}>
          No
        </Button>
        <Button colorScheme="red" isLoading={loading} onClick={deleteBlock}>
          Yes, delete it
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

DeleteBlock.propTypes = {
  children: PropTypes.node.isRequired,
  variables: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired
};
