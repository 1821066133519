import Footer from './Footer';
import Header, {HEADER_HEIGHT} from './Header';
import PropTypes from 'prop-types';
import React from 'react';
import {Box, Button, Center, Heading, Text} from '@chakra-ui/react';
import {FiArrowLeft} from 'react-icons/fi';
import {Link as GatsbyLink} from 'gatsby';

export default function NotFound({title, description, buttonProps}) {
  return (
    <>
      <Header />
      <Center
        css={({theme}) => ({
          minHeight: `calc(100vh - ${theme.space[HEADER_HEIGHT]})`
        })}
      >
        <Box maxW="container.sm" w="full" pb="10">
          <Heading mb="2" size="2xl">
            {title}
          </Heading>
          <Text mb="6" fontSize="lg">
            {description}
          </Text>
          <Button
            as={GatsbyLink}
            rounded="full"
            leftIcon={<FiArrowLeft />}
            {...buttonProps}
          />
        </Box>
      </Center>
      <Footer />
    </>
  );
}

NotFound.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  buttonProps: PropTypes.object.isRequired
};
