import DeleteBlock from './DeleteBlock';
import Highlighter from 'react-highlight-words';
import Notes from './Notes';
import PropTypes from 'prop-types';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import SubmitButton from './SubmitButton';
import {BLOCK_FRAGMENT, GET_PROJECT} from '../utils/queries';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Link,
  Modal,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import {MdPause, MdPlayArrow} from 'react-icons/md';
import {
  NEW_BLOCK_ID,
  ProjectContext,
  QueryContext,
  getTimecode
} from '../utils';
import {gql, useMutation} from '@apollo/client';

const NotesInput = React.lazy(() => import('./NotesInput'));

const CREATE_BLOCK = gql`
  mutation CreateBlock($projectId: ID!, $input: BlockInput!) {
    block: createBlock(projectId: $projectId, input: $input) {
      ...BlockFragment
      project {
        id
      }
    }
  }
  ${BLOCK_FRAGMENT}
`;

const UPDATE_BLOCK = gql`
  mutation UpdateBlock($id: ID!, $input: BlockInput!) {
    block: updateBlock(id: $id, input: $input) {
      ...BlockFragment
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const BLOCK_SPACING = [2, 3, 4];

function updateProject(cache, {data}) {
  const queryOptions = {
    query: GET_PROJECT,
    variables: {
      projectId: data.block.project.id
    }
  };

  const {project} = cache.readQuery(queryOptions);
  cache.writeQuery({
    ...queryOptions,
    data: {
      project: {
        ...project,
        blocks: [...project.blocks, data.block]
      }
    }
  });
}

export function BlockWrapper({isStatic, isSelected, ...props}) {
  const hoverProps = !isStatic && {
    role: 'group',
    bg: isSelected && 'indigo.50',
    transitionProperty: 'background',
    transitionDuration: '250ms',
    _hover: !isSelected && {bg: 'gray.50'}
  };
  return (
    <Box
      p={BLOCK_SPACING}
      rounded={{base: 'lg', md: 'xl'}}
      {...hoverProps}
      {...props}
    />
  );
}

BlockWrapper.propTypes = {
  isSelected: PropTypes.bool,
  isStatic: PropTypes.bool
};

export function BlockButtonGroup({isSelected, ...props}) {
  return (
    <ButtonGroup
      size="sm"
      ml="auto"
      colorScheme="indigo"
      opacity={isSelected ? 1 : 0}
      visibility={!isSelected && 'hidden'}
      transitionProperty="opacity, visibility"
      transitionDuration="inherit"
      _groupHover={{
        opacity: 1,
        visibility: 'visible'
      }}
      {...props}
    />
  );
}

BlockButtonGroup.propTypes = {
  isSelected: PropTypes.bool
};

export default function Block({block}) {
  const editorRef = useRef();
  const toast = useToast();
  const {query} = useContext(QueryContext);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {
    playing,
    togglePlaying,
    selected,
    setSelected,
    selectBlock,
    editing,
    setEditing,
    canEdit,
    playPauseBlock,
    project,
    sound
  } = useContext(ProjectContext);

  const isNew = useMemo(() => block.id === NEW_BLOCK_ID, [block.id]);

  const isSelected = useMemo(() => selected?.id === block.id, [
    selected,
    block.id
  ]);

  const isEditing = useMemo(() => isSelected && editing, [isSelected, editing]);

  const timecode = useMemo(() => getTimecode(isEditing ? selected : block), [
    isEditing,
    selected,
    block
  ]);

  useEffect(() => {
    if (isSelected) {
      document.getElementById(block.id).scrollIntoView();
    }
  }, [block.id, isSelected]);

  const onCompleted = useCallback(
    ({block}) => {
      setSelected(block);
      setEditing(false);

      const time = sound.current?.seek();
      if (time < block.start || time >= block.end) {
        // if the current time is within the bounds of the block, do nothing
        sound.current.seek(block.start);
      }
    },
    [setEditing, setSelected, sound]
  );

  const [mutate, {loading}] = useMutation(isNew ? CREATE_BLOCK : UPDATE_BLOCK, {
    variables: {projectId: project.id},
    update: isNew && updateProject,
    onCompleted,
    onError: error =>
      toast({
        status: 'error',
        title: 'There was a problem',
        description: error.message
      })
  });

  function handleSubmit(event) {
    event.preventDefault();
    mutate({
      variables: {
        id: block.id,
        input: {
          start: selected.start,
          end: selected.end,
          name: event.target.name.value,
          description: editorRef.current.getValue()
        }
      }
    });
  }

  return (
    <BlockWrapper
      isSelected={isSelected}
      as={isEditing && 'form'}
      onSubmit={handleSubmit}
      role="group"
      id={block.id}
      onDoubleClick={isEditing ? null : () => selectBlock(block)}
    >
      <Flex align="center">
        <Flex align="center">
          <Heading
            as="h3"
            size="lg"
            letterSpacing="tight"
            color={isSelected && 'indigo.500'}
          >
            {isNew ? (
              timecode
            ) : (
              <Link
                letterSpacing="inherit"
                fontWeight="inherit"
                href={'#' + block.id}
                onClick={() => selectBlock(block)}
              >
                {timecode}
              </Link>
            )}
          </Heading>
          {project.file && (
            <IconButton
              colorScheme={isSelected ? 'indigo' : 'gray'}
              icon={isSelected && playing ? <MdPause /> : <MdPlayArrow />}
              fontSize="2xl"
              variant={isSelected ? 'solid' : 'outline'}
              size="sm"
              ml="3"
              rounded="full"
              onClick={() => playPauseBlock(block)}
            />
          )}
        </Flex>
        {canEdit && (
          <BlockButtonGroup isSelected={isSelected}>
            <Button
              onClick={
                isEditing
                  ? () => {
                      setEditing(false);
                      if (isNew) {
                        setSelected(null);
                      }
                    }
                  : onOpen
              }
              variant="ghost"
            >
              {isEditing ? 'Cancel' : 'Delete'}
            </Button>
            {isEditing ? (
              <SubmitButton isLoading={loading} />
            ) : (
              <Button
                onClick={() => {
                  selectBlock(block);
                  togglePlaying(false);
                  setEditing(true);
                }}
              >
                Edit
              </Button>
            )}
          </BlockButtonGroup>
        )}
      </Flex>
      {isEditing ? (
        <Stack mt="3" spacing="4">
          <FormControl isRequired>
            <FormLabel>Block name</FormLabel>
            <Input autoFocus bg="white" defaultValue={block.name} name="name" />
          </FormControl>
          <FormControl>
            <FormLabel>Block notes</FormLabel>
            <Suspense fallback={null}>
              <NotesInput initialValue={block.description} ref={editorRef} />
            </Suspense>
          </FormControl>
        </Stack>
      ) : (
        <>
          <Box as="h4" fontSize={{base: 'lg', md: 'xl'}} lineHeight="short">
            <Highlighter searchWords={[query]} textToHighlight={block.name} />
          </Box>
          {block.description && <Notes source={block.description} />}
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <DeleteBlock onCancel={onClose} variables={{id: block.id}}>
          <Text>
            Are you sure you want to permanently delete{' '}
            <strong>{block.name}</strong>?
          </Text>
        </DeleteBlock>
      </Modal>
    </BlockWrapper>
  );
}

Block.propTypes = {
  block: PropTypes.object.isRequired
};
