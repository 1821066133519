import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@chakra-ui/react';
import {gql, useMutation} from '@apollo/client';

const DELETE_NOTES = gql`
  mutation DeleteNotes($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      notes
    }
  }
`;

export default function DeleteNotes({variables}) {
  const [deleteNotes, {loading}] = useMutation(DELETE_NOTES, {variables});
  // TODO: add an alert like deleting a block
  return (
    <Button variant="ghost" onClick={deleteNotes} isLoading={loading}>
      Clear
    </Button>
  );
}

DeleteNotes.propTypes = {
  variables: PropTypes.object.isRequired
};
