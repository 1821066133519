import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Box,
  Heading,
  Link,
  OrderedList,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import {QueryContext} from '../utils';

function HighlightedText({value}) {
  const {query} = useContext(QueryContext);
  return <Highlighter searchWords={[query]} textToHighlight={value} />;
}

HighlightedText.propTypes = {
  value: PropTypes.string.isRequired
};

function CustomList({ordered, children}) {
  return (
    <div>
      {React.createElement(
        ordered ? OrderedList : UnorderedList,
        {spacing: 1},
        children
      )}
    </div>
  );
}

CustomList.propTypes = {
  ordered: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const headingFontSizes = {
  1: '4xl',
  2: '3xl',
  3: '2xl',
  4: 'xl',
  5: 'lg',
  6: 'md'
};

function CustomHeading({level, ...props}) {
  return <Heading as={'h' + level} size={headingFontSizes[level]} {...props} />;
}

CustomHeading.propTypes = {
  level: PropTypes.number.isRequired
};

function Root(props) {
  return <Stack spacing="4" mt="3" {...props} />;
}

function Blockquote(props) {
  return (
    <Box
      as="blockquote"
      px="2"
      py={1 / 2}
      color="gray.500"
      borderColor="current"
      fontStyle="italic"
      fontWeight="medium"
      borderLeftWidth="2px"
      {...props}
    />
  );
}

const renderers = {
  root: Root,
  paragraph: Text,
  heading: CustomHeading,
  list: CustomList,
  text: HighlightedText,
  link: Link,
  blockquote: Blockquote
};

export default function Notes({source}) {
  const {transformImage} = useContext(QueryContext);
  return (
    <ReactMarkdown
      skipHtml
      transformImageUri={transformImage}
      source={source}
      renderers={renderers}
    />
  );
}

Notes.propTypes = {
  source: PropTypes.string.isRequired
};
