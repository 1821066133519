import React, {useRef, useState} from 'react';
import {Button, chakra} from '@chakra-ui/react';
import {useKey, useMount} from 'react-use';

export default function SubmitButton(props) {
  const buttonRef = useRef();
  const [mac, setMac] = useState(false);

  useMount(() => {
    // test if user is on mac
    setMac(navigator.platform.indexOf('Mac') !== -1);
  });

  useKey(
    event => event.code === 'KeyS' && (mac ? event.metaKey : event.ctrlKey),
    event => {
      event.preventDefault();
      buttonRef.current.click();
    }
  );

  return (
    <Button ref={buttonRef} type="submit" {...props}>
      <span>Save</span>
      <chakra.span ml="2" color="whiteAlpha.700">
        {mac ? '⌘' : 'Ctrl'}+S
      </chakra.span>
    </Button>
  );
}
