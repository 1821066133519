import DeleteNotes from './DeleteNotes';
import GeneralNotesForm from './GeneralNotesForm';
import Notes from './Notes';
import React, {useCallback, useContext, useState} from 'react';
import {BlockButtonGroup, BlockWrapper} from './Block';
import {Button, Flex, Heading} from '@chakra-ui/react';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import {ProjectContext} from '../utils';

export default function GeneralNotes() {
  const {project, canEdit} = useContext(ProjectContext);
  const [isShown, setIsShown] = useState(true);
  const [formShown, setFormShown] = useState(false);
  const showForm = useCallback(() => setFormShown(true), [setFormShown]);
  return (
    <BlockWrapper
      isSelected={formShown}
      onDoubleClick={formShown ? null : () => setFormShown(true)}
    >
      {formShown ? (
        <GeneralNotesForm closeForm={() => setFormShown(false)} />
      ) : !project.notes ? (
        <Heading
          size="lg"
          color="gray.300"
          as="button"
          transitionProperty="color"
          transitionDuration="200ms"
          _hover={{color: 'indigo.500'}}
          onClick={showForm}
        >
          Add notes
        </Heading>
      ) : (
        <>
          <Flex align="center">
            <Heading size="lg">Notes</Heading>
            <Button
              size="xs"
              ml="3"
              variant="outline"
              leftIcon={isShown ? <FiEyeOff /> : <FiEye />}
              onClick={() => setIsShown(prev => !prev)}
              onDoubleClick={event => event.stopPropagation()}
            >
              {isShown ? 'Hide' : 'Show'}
            </Button>
            {canEdit && (
              <BlockButtonGroup isSelected={formShown}>
                <DeleteNotes
                  variables={{
                    id: project.id,
                    input: {notes: null}
                  }}
                />
                <Button onClick={showForm}>Edit</Button>
              </BlockButtonGroup>
            )}
          </Flex>
          {isShown && <Notes source={project.notes} />}
        </>
      )}
    </BlockWrapper>
  );
}
