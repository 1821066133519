import Howler from 'react-howler';
import ProjectMain from './ProjectMain';
import ProjectSidebar from './ProjectSidebar';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import sortBy from 'lodash/sortBy';
import {
  Center,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner
} from '@chakra-ui/react';
import {Helmet} from 'react-helmet';
import {NEW_BLOCK_ID, ProjectContext, UserContext} from '../utils';
import {gql, useMutation} from '@apollo/client';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import {useInterval, useToggle} from 'react-use';

const UPDATE_DURATION = gql`
  mutation UpdateDuration($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      duration
    }
  }
`;

export default function ProjectView({project}) {
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState(null);

  const sound = useRef();
  const [playing, togglePlaying] = useToggle(false);
  const [duration, setDuration] = useState(project.duration);
  const [time, setTime] = useState(0);
  const [rate, setRate] = useState(1);

  const [updateDuration] = useMutation(UPDATE_DURATION, {
    variables: {id: project.id}
  });

  useInterval(
    () => {
      const newTime = sound.current.seek();
      if (selected && !editing && newTime >= selected.end) {
        sound.current.seek(selected.start);
        togglePlaying(false);
        setTime(selected.start);
      } else {
        setTime(newTime);
      }
    },
    playing ? 10 : null
  );

  const sortedBlocks = useMemo(
    () =>
      // sort blocks by start time and break ties with end time
      sortBy(project.blocks, ['start', 'end']),
    [project.blocks]
  );

  const selectBlock = useCallback(
    block => {
      setEditing(false);
      setSelected(block);
      sound.current?.seek(block.start);
    },
    [setSelected]
  );

  const createBlock = useCallback(() => {
    if (selected) {
      sound.current?.seek(selected.end);
    }

    setSelected({
      id: NEW_BLOCK_ID,
      start: selected ? selected.end : sound.current?.seek() || 0,
      end: duration
    });
    setEditing(true);
  }, [duration, selected]);

  const playPauseBlock = useCallback(
    block => {
      if (selected?.id === block.id) {
        togglePlaying();
      } else {
        selectBlock(block);
        togglePlaying(true);
      }
    },
    [selectBlock, selected, togglePlaying]
  );

  const viewFullSong = useCallback(() => {
    setEditing(false);
    setSelected(null);
  }, [setEditing, setSelected]);

  const {user} = useContext(UserContext);
  const canEdit = useMemo(
    () =>
      project.isOwn ||
      project.collaborators.find(collab => collab.id === user.id)?.role ===
        'edit',
    [project.collaborators, project.isOwn, user.id]
  );

  return (
    <>
      <Helmet title={project.name}>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      {project.file && (
        <Howler
          playing={playing}
          src={process.env.GATSBY_API_URL + '/files/' + project.file.key}
          ref={sound}
          format={['mp3']}
          xhr={{
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                process.env.GATSBY_TOKEN_KEY
              )}`
            }
          }}
          onLoad={() => {
            const duration = sound.current.duration();
            setDuration(duration);

            if (!project.duration && canEdit) {
              // save duration on the project if one isn't already set
              updateDuration({
                variables: {
                  input: {
                    duration
                  }
                }
              });
            }

            sound.current.howler.on('rate', () =>
              setRate(sound.current.howler.rate())
            );
            sound.current.howler.on('seek', () =>
              setTime(sound.current.seek())
            );
          }}
          onPlay={() =>
            trackCustomEvent({
              category: 'Audio',
              action: 'Play'
            })
          }
          onPause={() =>
            trackCustomEvent({
              category: 'Audio',
              action: 'Pause'
            })
          }
          onEnd={() => {
            togglePlaying(false);
            trackCustomEvent({
              category: 'Audio',
              action: 'Ended'
            });
          }}
        />
      )}
      <ProjectContext.Provider
        value={{
          sound,
          duration,
          playing,
          togglePlaying,
          time,
          setTime,
          rate,
          // shared state
          editing,
          setEditing,
          selected,
          setSelected,
          // other stuff
          project,
          canEdit,
          blocks: sortedBlocks,
          selectBlock,
          createBlock,
          playPauseBlock,
          viewFullSong
        }}
      >
        <ProjectSidebar />
        <ProjectMain />
      </ProjectContext.Provider>
      <Modal isCentered isOpen={duration === null}>
        <ModalOverlay />
        <ModalContent w="auto">
          <Center py={{base: 4, md: 6}} px={{base: 6, md: 8}}>
            <Spinner />
            <Heading ml="4" size="md" fontWeight="medium">
              Loading song...
            </Heading>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
}

ProjectView.propTypes = {
  project: PropTypes.object.isRequired
};
