import Marker from './Marker';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {ProjectContext, isTyping} from '../../utils';
import {useKey} from 'react-use';

export default function Markers({trackRef}) {
  const {selected, duration, setSelected, sound} = useContext(ProjectContext);

  useKey(
    event => ['KeyI', 'KeyO'].includes(event.code) && !isTyping(event),
    event =>
      setSelected(prevSelected => ({
        ...prevSelected,
        [event.code === 'KeyI' ? 'start' : 'end']: sound.current.seek()
      }))
  );

  return (
    <>
      <Marker
        trackRef={trackRef}
        property="start"
        time={selected.start}
        max={selected.end / duration}
      />
      <Marker
        trackRef={trackRef}
        property="end"
        time={selected.end}
        min={selected.start / duration}
      />
    </>
  );
}

Markers.propTypes = {
  trackRef: PropTypes.object.isRequired
};
