import Markers from './Markers';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useRef} from 'react';
import clockTime from 'clock-time';
import {Box, HStack, Text} from '@chakra-ui/react';
import {ProjectContext} from '../../utils';
import {useSlider, useUpdateEffect} from 'react-use';

export function TrackBase({start, end, children}) {
  return (
    <HStack
      flexGrow="1"
      align="center"
      fontSize="sm"
      my={{base: 1, md: 0}}
      mx={{base: 0, md: 3}}
    >
      <Text>{start}</Text>
      {children({
        h: 1,
        bg: 'currentColor'
      })}
      <Text>{end}</Text>
    </HStack>
  );
}

TrackBase.propTypes = {
  children: PropTypes.func.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
};

function TrackMask({scale, ...props}) {
  return (
    <Box
      h="full"
      position="absolute"
      top="0"
      transformOrigin="right"
      bg="gray.800"
      w={`calc(100% * ${scale})`}
      {...props}
    />
  );
}

TrackMask.propTypes = {
  scale: PropTypes.number.isRequired
};

export default function Track() {
  const {
    sound,
    time,
    setTime,
    duration,
    selected,
    editing,
    project
  } = useContext(ProjectContext);

  const trackRef = useRef(null);
  const {isSliding, value} = useSlider(trackRef);
  const activeBlock = useMemo(() => !editing && selected, [selected, editing]);

  useUpdateEffect(() => {
    // after a slide completely finishes
    if (!isSliding && project.file) {
      let time = duration * value;
      if (activeBlock) {
        time =
          time >= selected.end
            ? selected.start
            : Math.max(selected.start, Math.min(selected.end, time));
      }

      setTime(time);
      sound.current.seek(time);
    }
  }, [isSliding]);

  const currentTime = useMemo(() => (isSliding ? duration * value : time), [
    isSliding,
    duration,
    value,
    time
  ]);

  const startTime = useMemo(
    () =>
      activeBlock
        ? Math.min(activeBlock.end, Math.max(currentTime, activeBlock.start))
        : currentTime,
    [activeBlock, currentTime]
  );

  const endTime = useMemo(() => activeBlock?.end || duration, [
    activeBlock,
    duration
  ]);

  return (
    <TrackBase
      start={clockTime(startTime * 1000)}
      end={clockTime(endTime * 1000)}
    >
      {trackProps => (
        <Box flexGrow="1" position="relative">
          <Box ref={trackRef} py="1" cursor="pointer">
            <Box position="relative" {...trackProps}>
              <Box
                bg="pink.500"
                h="full"
                transformOrigin="left"
                style={{
                  transform: `scaleX(${isSliding ? value : time / duration})`
                }}
              />
              {activeBlock && (
                <>
                  <TrackMask scale={selected.start / duration} left="0" />
                  <TrackMask
                    scale={(duration - selected.end) / duration}
                    right="0"
                  />
                </>
              )}
            </Box>
          </Box>
          {selected && editing && <Markers trackRef={trackRef} />}
        </Box>
      )}
    </TrackBase>
  );
}
